<template>
    <div class="row current-doc">
        <div class="col-12 col-md-2 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Номер:
            </p>
            <p class="item-text">
                {{ doc.number ? doc.number : '-' }}
            </p>
        </div>
        <div class="col-12 col-md-2 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Дата:
            </p>
            <p class="item-text">
                {{ doc.date ? doc.date : '-' | moment("DD.MM.YYYY") }}
            </p>
        </div>
        <div class="col-12 col-md-8 col-lg-4 mb-3 d-flex flex-column justify-content-start">
            <p class="mr-2">
                Договор:
            </p>
            <p class="item-text">
                <button :id="'btn-contract-caption-'+doc.contractId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openContract(doc.contractId)">{{ doc.contractCaption }}</button>
            </p>
        </div>
        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Сумма:
            </p>
            <p class="item-text">
                {{ doc.amountDisplay ? doc.amountDisplay : '-' }}
            </p>
        </div>
        <div class="col-12 col-md-8 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Подписант:
            </p>
            <p class="item-text">
                {{ doc.signatoryName ? doc.signatoryName : '-' }}
            </p>
        </div>
        <div v-if="!doc.isVerified && (doc.signatoryId == Agent.contactId || !doc.signatoryId)" class="col-12 mb-3 d-flex flex-row flex-md-column justify-content-start">
            <p class="mt-2 text-right">
                <DxButton
                        v-if="isSing"
                        text="Обработка..."
                        type="default"
                        styling-mode="contained"
                        :disabled="true"
                        icon="rename"
                />
                <DxButton
                        v-if="!doc.isVerified && !isSing && doc.signatoryId && doc.signatoryId != Agent.contactId"
                        text="Отозвать подпись"
                        type="default"
                        styling-mode="contained"
                        :disabled="true"
                        icon="remove"
                />
                <DxButton
                        v-if="!doc.primaryDocumentId && doc.isVerified"
                        text="Подписать документ"
                        type="default"
                        styling-mode="contained"
                        :disabled="true"
                        icon="rename"
                />
                <DxButton
                        v-if="doc.primaryDocumentId && doc.isVerified"
                        text="Отозвать подпись"
                        type="default"
                        styling-mode="contained"
                        :disabled="true"
                        icon="remove"
                />
                <DxButton
                        v-if="!doc.isVerified && !isSing && !doc.signatoryId && doc.primaryDocumentId"
                        text="Подписать документ"
                        type="default"
                        styling-mode="contained"
                        @click="addSing"
                        icon="rename"
                />
                <DxButton
                        v-if="!doc.isVerified && !isSing && doc.signatoryId && doc.signatoryId == Agent.contactId && doc.primaryDocumentId"
                        text="Отозвать подпись"
                        type="default"
                        styling-mode="contained"
                        @click="delSing"
                        icon="remove"
                />
            </p>
        </div>
        <div class="col-12 mb-3 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Перевозка(и):
            </p>
            <p class="item-text">
                {{ doc.carriageCaption ? doc.carriageCaption : '-' }}
            </p>
        </div>
        <div class="col-12 mb-3 d-flex flex-column justify-content-start">
            <p class="mr-2">
                Маршрут:
            </p>
            <p class="item-text">
                {{ doc.routeCaption ? doc.routeCaption : '-' }}
            </p>
        </div>
        <div class="col-12 mb-3 d-flex flex-column justify-content-start">
            <p class="mr-2">
                Способ и условия оплаты:
            </p>
            <p class="item-text">
                {{getMethodOfPaymentName(doc.methodOfPayment)}}. {{ doc.paymentTermCaption ? doc.paymentTermCaption : '-' }}
            </p>
        </div>
        <div class="col-12">
            <p>
                <strong>Нормы времени на погрузку/выгрузку</strong>
            </p>
            <hr>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На погрузку по РК:
            </p>
            <p class="item-text">
                {{ doc.timingLoadingKZ }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На погрузку по СНГ:
            </p>
            <p class="item-text">
                {{ doc.timingLoadingUIS }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На погрузку прочие:
            </p>
            <p class="item-text">
                {{ doc.timingLoadingOther }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На выгрузку по РК:
            </p>
            <p class="item-text">
                {{ doc.timingUnloadingKZ }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На выгрузку по СНГ:
            </p>
            <p class="item-text">
                {{ doc.timingUnloadingUIS }} ч.
            </p>
        </div>
        <div class="col-12 col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                На выгрузку прочие:
            </p>
            <p class="item-text">
                {{ doc.timingUnloadingOther }} ч.
            </p>
        </div>
        <div class="col-12">
            <p>
                <strong>Выделенный подвижной состав</strong>
            </p>
            <hr>
        </div>
        <div class="col-12 col-md-6 col-lg-6 d-flex flex-row flex-md-column justify-content-start">
            <p class="mr-2">
                Водитель:
            </p>
            <p class="item-text">
                {{ doc.driverName ? doc.driverName : '-' }}
            </p>
        </div>
        <div class="col-12 col-md-6 col-lg-6 mb-3 d-flex flex-column justify-content-start">
            <p class="mr-2">
                Транспорт:
            </p>
            <p class="item-text">
                {{ doc.carCaption ? doc.carCaption : '-' }}
            </p>
        </div>
        <div class="col-12 mb-3">
            <ul class="nav nav-tabs" :id="'routePointsTab-'+doc.primaryDocumentId" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" :id="'routePoints-tab-'+doc.primaryDocumentId" data-toggle="tab" :href="'#routePoints-'+doc.primaryDocumentId" role="tab" :aria-controls="'routePoints-'+doc.primaryDocumentId" aria-selected="true">Детали маршрута</a>
                </li>
            </ul>
            <div class="tab-content" :id="'routePointsTabContent-'+doc.primaryDocumentId">
                <div class="tab-pane fade show active" :id="'routePoints-'+doc.primaryDocumentId" role="tabpanel" :aria-labelledby="'routePoints-tab-'+doc.primaryDocumentId">
                    <DxDataGrid
                            id="gridContainerRoutePoints"
                            :ref="dataGridRefKeyRoutePoints"
                            :data-source="routePoints"
                            :columns="columnsRoutePoints"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :show-row-lines="true"
                            :customize-columns="customizeColumnsRoutePoints"
                    >
                        <template #route-points-Nn-cell-template="{ data }">
                            <div>
                                <p>
                                    {{ getRoutePointsNn(data) }}
                                </p>
                            </div>
                        </template>
                        <template #route-points-addresseeName-cell-template="{ data }">
                            <div>
                                {{ data.data.addresseeName }}<br>
                                {{ data.data.address ? data.data.address.caption : '' }}<br>
                                {{ data.data.contactData }} {{ data.data.contactName }}
                            </div>
                        </template>
                        <template #route-points-operationType-cell-template="{ data }">
                            <div>
                                <p>
                                    {{ getOperationTypeName(data) }}
                                </p>
                            </div>
                        </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>
        <div class="col-12">
            <p>
                <strong>Детали перевозок</strong>
            </p>
            <hr>
        </div>
        <div v-if="doc.rollingStocks.length == 0" class="col-12 mb-3">
            Ничего не найдено.
        </div>
        <div class="col-12 mb-3" v-for="rollingStocksItem in doc.rollingStocks" :key="rollingStocksItem.rollingStockId">
            <div class="card h-100">
                <div class="card-header bg-white" @click="openRollingStockDetail(rollingStocksItem.rollingStockId)" style="cursor: pointer;">
                    <div class="row">
                        <div class="col-6 text-left">
                            <small class="mb-2">{{ rollingStocksItem.acn }}</small>
                        </div>
                        <div class="col-6 text-right">
                            <i class="fas fa-chevron-up pt-1" :id="'rollingStocksChevron-'+rollingStocksItem.rollingStockId"></i>
                        </div>
                    </div>
                    <div>
                        {{ rollingStocksItem.routeCaption }}
                    </div>
                </div>
                <div :id="'card-'+rollingStocksItem.rollingStockId" class="card-body d-none">
                    <div class="row current-doc">
                        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Номер:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.acn ? rollingStocksItem.acn : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Дата начала:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.startTime ? rollingStocksItem.startTime : '-' | moment("DD.MM.YYYY") }}
                            </p>
                        </div>
                        <div class="col-12 col-md-4 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Дата окончания:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.finishTime ? rollingStocksItem.finishTime : '-' | moment("DD.MM.YYYY") }}
                            </p>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Начало маршрута:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.firstLocality.localityDisplay ? rollingStocksItem.firstLocality.localityDisplay : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-3 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Окончание маршрута:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.lastLocality.localityDisplay ? rollingStocksItem.lastLocality.localityDisplay : '-' }}
                            </p>
                        </div>
                        <div class="col-12 mb-3 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Описание груза:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.descriptionOfCargo ? rollingStocksItem.cargo.descriptionOfCargo : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-3 col-lg-1 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Вес (т):
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.weight ? rollingStocksItem.cargo.weight/1000 : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Объем (куб.м.):
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.volume ? rollingStocksItem.cargo.volume : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Кол-во мест:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.quantity ? rollingStocksItem.cargo.quantity : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3 mb-3 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Вид упаковки:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.packageType ? rollingStocksItem.cargo.packageType : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Объяв. стоимоть:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.declareAmount ? rollingStocksItem.cargo.declareAmount : '-' | formatMoney2 }}
                            </p>
                        </div>
                        <div class="col-12 col-md-2 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Валюта:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.currencyName ? rollingStocksItem.cargo.currencyName : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-12 col-lg-9 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Особые условия перевозки:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.additionCarriageTerm ? rollingStocksItem.cargo.additionCarriageTerm : '-' }}
                            </p>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3 d-flex flex-row flex-md-column justify-content-start">
                            <p class="mr-2">
                                Способ погрузки:
                            </p>
                            <p class="item-text">
                                {{ rollingStocksItem.cargo && rollingStocksItem.cargo.loadingType ? rollingStocksItem.cargo.loadingType : '-' }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-muted">
                    <div class="row">
                        <div class="col-6 pt-1 font-14 text-muted">
                            {{ rollingStocksItem.amountDisplay }}
                        </div>
                        <div class="col-6 pt-1 font-14 text-right text-muted">
                            <i class="far fa-calendar-alt mr-2"></i> {{ rollingStocksItem.startTime | moment("DD.MM.YYYY") }}<span v-if="rollingStocksItem.closedDate"> - {{ rollingStocksItem.closedDate | moment("DD.MM.YYYY") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mb-3">
            <ul class="nav nav-tabs" :id="'elementsTab-'+doc.primaryDocumentId" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" :id="'elements-tab-'+doc.primaryDocumentId" data-toggle="tab" :href="'#elements-'+doc.primaryDocumentId" role="tab" :aria-controls="'elements-'+doc.primaryDocumentId" aria-selected="true">Услуги по документу</a>
                </li>
            </ul>
            <div class="tab-content" :id="'elementsTabContent-'+doc.primaryDocumentId">
                <div class="tab-pane fade show active" :id="'elements-'+doc.primaryDocumentId" role="tabpanel" :aria-labelledby="'elements-tab-'+doc.primaryDocumentId">
                    <DxDataGrid
                            id="gridContainerElementsService"
                            :ref="dataGridRefKeyElements"
                            :data-source="doc.elements"
                            :columns="columnsElementsService"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :show-row-lines="true"
                            :customize-columns="customizeColumnsElementsService"
                    >
                    </DxDataGrid>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-7 col-lg-9 d-flex flex-column justify-content-start">
            <p class="mr-2">
                Скан-копия документа:
            </p>
            <p v-if="!doc.scanFile" class="item-text">
                -
            </p>
            <p v-if="doc.scanFile" class="item-text">
                <button :id="'btn-download-file-'+doc.scanFile.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(doc.scanFile.fileId, doc.scanFile.fileName)">{{ doc.scanFile.fileName }}</button>
            </p>
        </div>
        <div class="col-12 col-md-5 col-lg-3 mt-3 text-right">
            <div id="form-container-add-scan">
                <div v-if="okMessageAddScan" class="alert alert-success mb-3" role="alert">
                    {{ okMessageAddScan }}
                </div>
                <div v-if="errorMessageAddScan" class="alert alert-danger mb-3" role="alert">
                    <strong>Внимание!</strong> {{ errorMessageAddScan }}
                </div>
                <form class="add-scan-form">
                    <DxForm
                            id="form-add-scan"
                            :col-count="1"
                            :form-data="formDataAddScan"
                            :read-only="false"
                            :show-colon-after-label="true"
                            :show-validation-summary="true"
                            :label-location="labelLocation"
                    >
                        <DxSimpleItem>
                            <DxLabel :visible="false" text="" />
                            <template #default>
                                <div>
                                    <div class="row text-right">
                                        <div class="col-12">
                                            <DxButton
                                                    v-if="!isAddScan"
                                                    id="scanFile-add-external"
                                                    text="Загрузить скан-копию"
                                                    type="default"
                                                    styling-mode="contained"
                                                    :disabled="doc.isVerified"
                                            />
                                            <DxButton
                                                    v-if="isAddScan"
                                                    text="Загрузка..."
                                                    type="default"
                                                    styling-mode="contained"
                                                    :disabled="true"
                                            />
                                        </div>
                                    </div>
                                    <DxFileUploader
                                            id="scanFile"
                                            dialog-trigger="#scanFile-add-external"
                                            :multiple="false"
                                            upload-mode="useForm"
                                            :visible="false"
                                            accept="image/jpeg,image/jpg,image/png,application/pdf"
                                            @value-changed="scanFileValueChanged"
                                    />
                                </div>
                            </template>
                        </DxSimpleItem>
                    </DxForm>
                </form>
            </div>
        </div>
        <div class="col-12">
            <hr>
        </div>
        <div class="col-12 col-md-9 col-lg-9 d-flex flex-row flex-md-column justify-content-start">
            <p class="mt-2 pb-0 mb-0" v-if="doc.verifiedCaption">
                <i class="fas fa-award font-22 mr-2" style="color: #00afef"></i>{{ doc.verifiedCaption }}
            </p>
        </div>
        <div class="col-12 col-md-3 col-lg-3">
            <p class="mt-2 text-right">
                <DxButton
                        v-if="loadingReports && !reports"
                        text="Загрузка..."
                        type="default"
                        styling-mode="contained"
                        :disabled="true"
                        icon="print"
                />
                <DxButton
                        v-if="!loadingReports && !reports"
                        text="Печать документа"
                        type="default"
                        styling-mode="contained"
                        :disabled="true"
                        icon="print"
                />
                <DxButton
                        v-if="loadingReports && reports"
                        text="Загрузка документа..."
                        type="default"
                        styling-mode="contained"
                        :disabled="true"
                        icon="print"
                />
                <DxDropDownButton
                        v-if="!loadingReports && reports"
                        text="Печать документа"
                        styling-mode="contained"
                        icon="print"
                        @item-click="clickBtnPrint"
                        :items="reports"
                        display-expr="reportCaption"
                        key-expr="code"
                        :element-attr="{class: 'dx-dropdownbutton-primary'}"
                />
            </p>
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import {httpClient} from '../../../shared/services';
    import { DxDataGrid } from 'devextreme-vue/data-grid';
    import DxDropDownButton from 'devextreme-vue/drop-down-button';
    import DxButton from 'devextreme-vue/button';
    import { DxFileUploader } from 'devextreme-vue/file-uploader';
    import {
        DxForm,
        DxLabel,
        DxSimpleItem
    } from 'devextreme-vue/form';

    const dataGridRefKeyRoutePoints = "routePoints-service-data-grid";
    const dataGridRefKeyElements = "elements-service-data-grid";

    export default {
        name: "ContractAppCarrierAInfo",
        props: ['doc', 'Agent', 'getPrimaryDocument'],
        components: {
            DxDataGrid,
            DxDropDownButton,
            DxButton,
            DxFileUploader,
            DxForm,
            DxLabel,
            DxSimpleItem
        },
        data() {
            return {
                dataGridRefKeyRoutePoints,
                dataGridRefKeyElements,
                columnsElementsService: [
                    {
                        caption: 'ACN',
                        dataField: 'acn',
                        allowSorting: true,
                        allowFiltering: true,
                        width: 130
                    },
                    {
                        caption: 'Дата',
                        dataField: 'elementDate',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowFiltering: true,
                        width: 140,
                        allowResizing: false,
                        sortIndex: 0,
                        sortOrder: "desc"
                    },
                    {
                        caption: 'Наименование',
                        dataField: 'serviceName',
                        allowSorting: true,
                        allowFiltering: true,
                    },
                    {
                        caption: 'К оплате',
                        dataField: 'amount',
                        dataType: 'number',
                        allowSorting: true,
                        allowFiltering: true,
                        width: 130,
                        customizeText: this.formatMoney2
                    }
                ],
                columnsRoutePoints: [
                    {
                        caption: 'Nn',
                        dataField: 'carriageId',
                        allowSorting: false,
                        allowFiltering: false,
                        width: 40,
                        cellTemplate: "route-points-Nn-cell-template",
                    },
                    {
                        caption: 'Время подачи ТС',
                        dataField: 'operationTime',
                        dataType: 'datetime',
                        format: 'dd.MM.yyyy HH:mm',
                        allowSorting: false,
                        allowFiltering: false,
                        width: 140,
                        allowResizing: false,
                        sortIndex: 0,
                        sortOrder: "asc"
                    },
                    {
                        caption: 'Грузоотправитель/Грузополучатель',
                        dataField: 'addresseeName',
                        allowSorting: false,
                        allowFiltering: false,
                        cellTemplate: "route-points-addresseeName-cell-template",
                    },
                    {
                        caption: 'Погр/Выгр',
                        dataField: 'operationType',
                        allowSorting: false,
                        allowFiltering: false,
                        cellTemplate: "route-points-operationType-cell-template",
                        width: 140,
                    },
                    {
                        caption: 'Перевозка',
                        dataField: 'carriageNumber',
                        allowSorting: false,
                        allowFiltering: false,
                        width: 140,
                    },
                ],
                reports: null,
                loadingReports: false,
                routePointsNn: 0,
                openRollingStocks: [],
                routePoints: [],
                okMessageAddScan: "",
                errorMessageAddScan: "",
                isAddScan: false,
                formDataAddScan: {},
                labelLocation: 'top',
                isSing: false,
            }
        },
        mounted() {
            this.routePoints = this.doc.routePoints.map(function(item) {
                let rollingStock = this.doc.rollingStocks.filter(rollingStock => rollingStock.carriageId == item.carriageId);
                item.carriageNumber = rollingStock && rollingStock.length > 0 ? rollingStock[0].cargoLotNumber : '';
                return item;
            }, this);

            this.getReports();
        },
        methods: {
            changeColumnsElementsService(columns) {
                if(columns.length > 0) {
                    columns[0].width = '130px';
                    columns[1].visible = true;
                    columns[2].visible = true;
                    columns[3].visible = true;

                    switch (this.$mq) {
                        case 'sm':
                        case 'md':
                            columns[0].hidingPriority = 4;
                            columns[1].hidingPriority = 3;
                            columns[2].hidingPriority = 2;
                            columns[3].hidingPriority = 1;
                            break;
                    }
                }

                return columns;
            },
            customizeColumnsElementsService(columns) { //columns
                this.changeColumnsElementsService(columns);
            },
            changeColumnsRoutePoints(columns) {
                if(columns.length > 0) {
                    columns[0].visible = true;
                    columns[1].width = '140px';
                    columns[2].visible = true;
                    columns[3].visible = true;
                    columns[4].visible = true;

                    switch (this.$mq) {
                        case 'sm':
                            columns[0].visible = false;
                            //columns[0].hidingPriority = 5;
                            columns[1].width = '100px';
                            columns[1].hidingPriority = 4;
                            columns[3].hidingPriority = 3;
                            columns[4].hidingPriority = 2;
                            columns[2].hidingPriority = 1;
                            break;
                    }
                }

                return columns;
            },
            customizeColumnsRoutePoints(columns) {
                this.changeColumnsRoutePoints(columns);
            },
            downloadFile(fileId, fileName) {
                $('#btn-download-file-'+fileId).html('Загрузка...');
                $('#btn-download-file-'+fileId).prop('disabled', true);

                httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                    .then((file) => {
                        const linkSource = `data:application/png;base64,${file.data}`;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);

                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click();

                        downloadLink.remove();

                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    })
                    .catch(() => {
                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    });
            },
            openContract(contractId) {
                let route = this.$router.resolve({name: 'ProfileContract', params: {contractId: contractId}});
                window.open(route.href, '_blank');
            },
            formatMoney2(options) {
                return this.$options.filters.formatMoney2(options.value);
            },
            getRoutePointsNn(data) {
                return data.rowIndex + 1;
            },
            getReports() {
                this.loadingReports = true;

                httpClient.get(`v2/PrimaryDocs/${this.doc.primaryDocumentId}/Reports?tokenId=${this.Agent.tokenId}`)
                    .then((reports) => {
                        this.reports = reports.data;
                        this.loadingReports = false;
                    })
                    .catch(this.handleErrorLoadingReports);
            },
            handleErrorLoadingReports: function (error) {
                console.error(error);
                this.loadingReports = false;
            },
            clickBtnPrint(e) {
                this.printDoc(e.itemData.code);
            },
            printDoc(reportCode) {
                this.loadingReports = true;

                httpClient.get(`v2/PrimaryDocs/${this.doc.primaryDocumentId}/Reports/${reportCode}?tokenId=${this.Agent.tokenId}`)
                    .then((pdf) => {
                        let byteCharacters = atob(pdf.data);
                        let byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        let byteArray = new Uint8Array(byteNumbers);
                        let file = new Blob([byteArray], {type: 'application/pdf;base64'});
                        let fileURL = URL.createObjectURL(file);
                        let wo = window.open(fileURL);
                        wo.print();

                        this.loadingReports = false;
                    })
                    .catch(this.handleErrorPrintDoc);
            },
            handleErrorPrintDoc: function (error) {
                console.error(error);
                this.loadingReports = false;
            },
            getOperationTypeName(operationType) {
                switch (operationType.value) {
                    case 'rptLoading':
                        return 'Погрузка';
                    case 'rtpUnloading':
                        return 'Выгрузка';
                }
            },
            getMethodOfPaymentName(methodOfPayment) {
                switch (methodOfPayment) {
                    case 'cmpNone':
                        return '';
                    case 'cmpCash':
                        return 'Наличный расчет';
                    case 'cmpAccount':
                        return 'Безналичный расчет';
                    case 'cmpCreditCard':
                        return 'Кредитная карта';
                    case 'cmpInternet':
                        return 'Интернет оплата';
                }
            },
            openRollingStockDetail(rollingStockId) {
                let id = 'card-'+rollingStockId;
                if($('#'+id).hasClass('d-none')) {
                    this.openRollingStocks.push(''+rollingStockId+'');

                    $('#'+id).removeClass('d-none');
                    $('#'+id+'-btn').html('Свернуть');
                } else {
                    $('#'+id).addClass('d-none');
                    $('#'+id+'-btn').html('Подробно');

                    const index = this.openRollingStocks.indexOf(rollingStockId);
                    if (index > -1) {
                        this.openRollingStocks.splice(index, 1);
                    }
                }

                this.changeRollingStockChevron(rollingStockId);
            },
            changeRollingStockChevron(id) {
                if($('#rollingStocksChevron-'+id).hasClass('fa-chevron-up')) {
                    $('#rollingStocksChevron-'+id).removeClass('fa-chevron-up');
                    $('#rollingStocksChevron-'+id).addClass('fa-chevron-down');
                } else {
                    $('#rollingStocksChevron-'+id).removeClass('fa-chevron-down');
                    $('#rollingStocksChevron-'+id).addClass('fa-chevron-up');
                }
            },
            scanFileValueChanged(args) {
                if(args.value[0]) {
                    this.okMessageAddScan = "";
                    this.errorMessageAddScan = "";
                    this.isAddScan = true;

                    this.saveBase64(args.value[0]);
                }
            },
            saveBase64(file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // use a regex to remove data url part
                    const base64String = reader.result
                        .replace("data:", "")
                        .replace(/^.+,/, "");

                    this.uploadFile(base64String, file.name);
                };
                reader.readAsDataURL(file);
            },
            uploadFile(base64String, fileName) {
                fileName = this.$options.filters.encodeURL(fileName);

                return httpClient
                    .post(`v2/PrimaryDocs/${this.doc.primaryDocumentId}/Upload?fileName=${fileName}&tokenId=${this.Agent.tokenId}`, `"${base64String}"`,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                    .then(() => {
                        this.getPrimaryDocument();
                        this.isAddScan = false;
                    }).catch(this.handleErrorSaveFile);
            },
            handleErrorSaveFile: function (error) {
                this.errorMessageAddScan = "Ошибка при сохранении файла. Попробуйте еще раз.";
                console.error(error);
                this.isAddScan = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .current-doc {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
