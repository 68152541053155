<template>
    <div>
        <vue-title title="ЗПп | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Заявки на перевозку<br>с перевозчиком</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <DxDataGrid
                            id="gridContainerDocs"
                            :ref="dataGridRefKey"
                            :data-source="storeDocs"
                            :columns="columnsDocs"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :show-row-lines="true"
                            :customize-columns="customizeColumns"
                            :paging="{pageSize: pageSize, enabled: true}"
                            :pager="pager"
                            :remote-operations="true"
                            :filter-row="{visible: true}"
                            @row-click="onRowClick"
                    >
                        <DxSelection mode="single"/>
                        <DxHeaderFilter
                                :visible="true"
                        />
                        <DxMasterDetail
                                :enabled="true"
                                template="masterDetailTemplate"
                        />
                        <template #masterDetailTemplate="{ data }">
                            <ShortPrimaryDocInfo
                                    v-if="selectedRowKey == data.key"
                                    :primaryDocumentId="data.key"
                                    :agent="Agent"
                                />
                        </template>
                        <template #amount-display-template="{ data }">
                            <div>
                                {{ data.data.amountDisplay }}
                            </div>
                        </template>
                        <template #is-verified-template="{ data }">
                            <i v-if="data.data.isVerified" class="fas fa-award font-22" style="color: #00afef"></i>
                        </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import { DxDataGrid, DxMasterDetail, DxSelection, DxHeaderFilter } from 'devextreme-vue/data-grid';
    import CustomStore from 'devextreme/data/custom_store';
    import moment from 'moment';

    import ShortPrimaryDocInfo from './detail/ShortPrimaryDocInfo';

    const dataGridRefKey = "docs-data-grid";

    export default {
        name: "PrimaryDocsContractAppCarriers",
        components: {
            Header,
            Footer,
            DxDataGrid,
            DxSelection,
            DxHeaderFilter,
            DxMasterDetail,
            ShortPrimaryDocInfo
        },
        data() {
            return {
                headerRoutes,
                dataGridRefKey,
                selectedRowKey: "",
                storeDocs: null,
                pager: {
                    allowedPageSizes: [5, 10, 15, 30],
                    showInfo: true,
                    infoText: 'Страница {0} из {1} (Всего {2})',
                    showNavigationButtons: true,
                    showPageSizeSelector: true,
                    visible: true
                },
                pageSize: 10,
                columnsDocs: [
                    {
                        caption: '',
                        dataField: 'isVerified',
                        allowSorting: false,
                        cellTemplate: "is-verified-template",
                        width: 40,
                        allowFiltering: false,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Номер',
                        dataField: 'number',
                        allowSorting: true,
                        width: 140,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Дата',
                        dataField: 'date',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowResizing: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false,
                        width: 140,
                    },
                    {
                        caption: 'Договор',
                        dataField: 'contractCaption',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Водитель',
                        dataField: 'driverName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Транспорт',
                        dataField: 'carCaption',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Сумма',
                        dataField: 'amountDisplay',
                        dataType: 'number',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false,
                        cellTemplate: "amount-display-template",
                    },
                ]
            }
        },
        mounted() {
            this.getDocs();
        },
        methods: {
            getDocs() {
                this.storeDocs = new CustomStore({
                    key: 'primaryDocumentId',
                    byKey: async function (key) {
                        if(key) {
                            console.log(key);
                        }
                    },
                    load: (loadOptions) => {

                        let tokenId = this.Agent.tokenId;
                        let sortStr = '';
                        let filterStr = '';
                        let takeStr = '';
                        let skeepStr = '';

                        if(loadOptions.take) {
                            takeStr = `&take=${loadOptions.take}`;
                        }

                        if(loadOptions.skip) {
                            skeepStr = `&skeep=${loadOptions.skip}`;
                        }

                        if (loadOptions.sort) {
                            let sort = loadOptions.sort[0];
                            sortStr = `&order_field=${sort.selector}&order_desc=${sort.desc}`;
                        }

                        if (loadOptions.filter) {
                            if(loadOptions.filter.columnIndex != undefined) { // Фильтрация только по одному полю
                                switch (loadOptions.filter.columnIndex) {
                                    case 2:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            let dateFilter = moment(loadOptions.filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], dateFilter);
                                        }
                                        break;
                                    default:
                                        filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], loadOptions.filter[2]);
                                        break;
                                }

                                filterStr = filterStr ? `&filter={"and":[${filterStr}]}` : '';
                            } else { // Фильтрация по нескольким полям
                                if(loadOptions.filter[0] != 'primaryDocumentId') {
                                    if(loadOptions.filter[0] != '!') {
                                        filterStr = this.getFilterGroup(loadOptions.filter);
                                    } else {
                                        if (Array.isArray(loadOptions.filter[1][0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter[1], true);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[1][0], '<>', loadOptions.filter[1][2]);
                                        }
                                    }

                                    filterStr = filterStr ? `&filter=${filterStr}` : '';
                                }
                            }
                        }

                        return httpClient.get(`v2/PrimaryDocs/ContractAppCarriers?tokenId=${tokenId}${takeStr}${skeepStr}${sortStr}${filterStr}`)
                            .then((invoices) => {
                                return {
                                    key: 'primaryDocumentId',
                                    data: invoices.data.items,
                                    totalCount: invoices.data.totals,
                                    summary: invoices.data.totals,
                                    groupCount: invoices.data.count,
                                    userData: invoices.data,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });
            },
            getFilterOp(op) {
                switch (op) {
                    case '>':
                        return 'gt';
                    case '>=':
                        return 'gte';
                    case '<':
                        return 'lt';
                    case '=<':
                    case '<=':
                        return 'lte';
                    case '=':
                        return 'eq';
                    case '<>':
                        return 'neq';
                    case 'contains':
                        return 'con';
                    case 'notcontains':
                        return 'ncon';
                    case 'startswith':
                        return 'sw';
                    case 'endswith':
                        return 'ew';
                }
            },
            getFilterItem(field_name, op, value) {
                if(field_name == 'amount' || field_name == 'amountDisplay') {
                    value = `${value}`;
                } else {
                    switch (value) {
                        case true:
                        case false:
                            value = `${value}`;
                            break;
                        default:
                            value = `"${value}"`;
                            break;
                    }
                }

                return `{"${field_name}":{"op":"${this.getFilterOp(op)}","value":${value}}}`;
            },
            getFilterGroup(group_filters, isNo = false) {
                let condition = '';
                let filter_items = '';

                group_filters.forEach(function (filter) {
                    if (Array.isArray(filter)) {
                        if(Array.isArray(filter[0])) {
                            filter_items += this.getFilterGroup(filter) + ',';
                        } else {
                            if(filter[0] == '!') {
                                if(Array.isArray(filter[1][0])) {
                                    filter_items += this.getFilterGroup(filter[1], true) + ',';
                                } else {
                                    filter_items += this.getFilterItem(filter[1][0], '<>', filter[1][2]) + ',';
                                }
                            } else {
                                switch (group_filters.columnIndex) {
                                    case 2:
                                        var dateFilter1 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                        filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter1) + ',';
                                        break;
                                    default:
                                        switch (filter.columnIndex) {
                                            case 2:
                                                var dateFilter2 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter2) + ',';
                                                break;
                                            default:
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), filter[2]) + ',';
                                                break;
                                        }
                                        break;
                                }
                            }
                        }
                    } else {
                        condition = filter;
                    }
                }, this);

                filter_items = filter_items.replace(/,$/, "");

                return `{"${condition}":[${filter_items}]}`;
            },
            changeColumns(columns) {
                if(columns.length > 0) {
                    columns[0].width = '40px';
                    columns[1].width = '140px';
                    columns[0].visible = true;
                    columns[1].visible = true;
                    columns[2].visible = true;
                    columns[3].visible = true;
                    columns[4].visible = true;
                    columns[5].visible = true;
                    columns[6].visible = true;

                    switch (this.$mq) {
                        case 'sm':
                            columns[0].width = '40px';
                            columns[0].visible = true;
                            columns[1].visible = true;
                            columns[2].visible = true;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = false;
                            break;
                        case 'md':
                        case 'lg':
                            columns[0].width = '40px';
                            columns[0].visible = true;
                            columns[1].visible = true;
                            columns[2].visible = true;
                            columns[3].visible = true;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = true;
                            break;
                    }
                }

                return columns;
            },
            customizeColumns(columns) { //columns
                this.changeColumns(columns);
            },
            onRowClick(e) {
                //console.log(e);
              if(e.rowType == "data" && !e.event.originalEvent.currentTarget.className.includes('dx-button') && !e.event.originalEvent.originalEvent.srcElement.className.includes('btn btn-link')) {
                    if(this.selectedRowKey != e.key) {
                        if(this.selectedRowKey != "") {
                            this.dataGrid.collapseRow(this.selectedRowKey);
                            this.selectedRowKey = "";
                        }

                        this.selectedRowKey = e.key;

                        this.dataGrid.expandRow(this.selectedRowKey);
                    } else {
                        this.dataGrid.collapseRow(this.selectedRowKey);

                        this.selectedRowKey = "";
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ]),
            dataGrid: function() {
                return this.$refs[dataGridRefKey].instance;
            }
        },
    }
</script>

<style scoped>

</style>
