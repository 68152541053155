<template>
    <div class="row">
        <div class="col-12">
            <div v-if="!primaryDocument">
                <DxLoadIndicator
                        id="medium-indicator"
                        :height="20"
                        :width="20"
                />
                Загрузка
            </div>
            <div v-if="primaryDocument">
                <ContractAppCarrierAInfo
                        v-if="primaryDocument.primaryDocumentKind == 'pdContractAppCarrierA'"
                        :doc="primaryDocument"
                        :Agent="Agent"
                        :getPrimaryDocument="getPrimaryDocument"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import localStore from './../store';

    import ContractAppCarrierAInfo from './ContractAppCarrierAInfo';

    export default {
        name: "ShortPrimaryDocInfo",
        props: ['primaryDocumentId', 'Agent'],
        components: {
            DxLoadIndicator,
            ContractAppCarrierAInfo
        },
        data() {
            return {
                primaryDocument: null
            }
        },
        mounted() {
            this.getPrimaryDocument();
        },
        methods: {
            getPrimaryDocument() {
                localStore.dispatch('getPrimaryDocument', {tokenId: this.Agent.tokenId, primaryDocumentId: this.primaryDocumentId}).then(() => {
                    this.primaryDocument = localStore.state.primaryDocument;
                }).catch(this.handleError);
            },
            handleError: function (error) {
                console.error(error);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .select-doc {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
