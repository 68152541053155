import Vue from 'vue'
import Vuex from 'vuex'
import {httpClient} from '../../shared/services';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        primaryDocument: null,
    },
    mutations: {
        setPrimaryDocument(state, data) {
            state.primaryDocument = data;
        },
    },
    actions: {
        getPrimaryDocument({commit}, {tokenId, primaryDocumentId}) {
            commit('setPrimaryDocument', null);

            return httpClient
                .get(`v2/PrimaryDocs/${primaryDocumentId}?tokenId=${tokenId}`)
                .then((response) => {
                    commit('setPrimaryDocument', response.data);
                });
        },
    },
    getters: {},
    modules: {}
});
